module.exports = {
  apiURL: process.env.VUE_APP_API_URL,
  jobEden: process.env.VUE_APP_JOBEDEN === 'true',
  machinaJob: process.env.VUE_APP_MACHINAJOB === 'true',
  coach: process.env.VUE_APP_COACH === 'true',
  collective: process.env.VUE_APP_COLLECTIVE === 'true',
  stats: process.env.VUE_APP_STATS === 'true',
  machinaJobFront: process.env.VUE_APP_MACHINAJOB_FRONT,
  admin: process.env.VUE_APP_ADMIN === 'true',
  env: process.env.VUE_APP_ENV
}
