import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import { jobEden, machinaJob, stats } from '@/../config'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  store.dispatch('set_pending', to.fullPath)
  next('/login')
}

const ifAdmin =  function(to,from,next){
  if(store.getters.isAuthenticated && !store.getters.isProfileLoaded){
    setTimeout(() => {
      return ifAdmin(to,from,next)
    }, 50)
  }else{
    if(store.getters.isAuthenticated && store.getters.isAdmin){
      next()
      return
    }else if(store.getters.isAuthenticated){
      store.dispatch('AUTH_LOGOUT')
    }
    next('/login')
  }
}

const kick = (to, from, next) => next('/')

function meta(title){
  const hyphen = title ? ' - ':''
  return{
    title: `${title}${hyphen}Jobtimise ADMIN`,
  }
}

const routes = [
  //regular routes
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: meta('Home')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue'),
    meta: meta('Test')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    beforeEnter: ifNotAuthenticated,
    meta: meta('Login')
  },
  //admin routes
    //courses
  {
    path: '/admin/courses',
    name: 'All Courses',
    component: () => import('../views/courses/All.vue'),
    beforeEnter: machinaJob ? ifAuthenticated : kick,
    meta: meta('All Courses')
  },
  {
    path: '/admin/courses/new',
    name: 'New Course',
    component: () => import('../views/courses/New.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
  {
    path: '/admin/courses/:id/edit',
    name: 'Edit Course',
    component: () => import('../views/courses/New.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
    //journeys
  {
    path: '/admin/journeys',
    name: 'All Journeys',
    component: () => import('../views/courses/All.vue'),
    beforeEnter: machinaJob ? ifAuthenticated : kick,
    props: {isJourney: true},
    meta: meta('All Journeys')
  },
  {
    path: '/admin/journeys/new',
    name: 'New Journey',
    component: () => import('../views/courses/New.vue'),
    props: {isJourney: true},
    beforeEnter: machinaJob ? ifAdmin : kick
  },
  {
    path: '/admin/journeys/:id/edit',
    name: 'Edit Journey',
    component: () => import('../views/courses/New.vue'),
    props: {isJourney: true},
    beforeEnter: machinaJob ? ifAdmin : kick
  },
    //sessions
  {
    path: '/admin/sessions',
    name: 'All Session',
    component: () => import('../views/sessions/All.vue'),
    beforeEnter: machinaJob ? ifAuthenticated : kick,
    meta: meta('All Sessions')
  },
  {
    path: '/admin/sessions/new',
    name: 'New Session',
    component: () => import('../views/sessions/New.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
  {
    path: '/admin/courses/:courseId/sessions/new',
    name: 'New Session in course',
    component: () => import('../views/sessions/New.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
  {
    path: '/admin/sessions/:sessionId/edit',
    name: 'Update Session',
    component: () => import('../views/sessions/New.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
  {
    path: '/admin/courses/:courseId/sessions/:sessionId/edit',
    name: 'Update Session in course',
    component: () => import('../views/sessions/New.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
  {
    path: '/admin/journeys/:courseId/sessions/:sessionId/edit',
    name: 'Update Session in journey',
    component: () => import('../views/sessions/New.vue'),
    props: {isJourney: true},
    beforeEnter: machinaJob ? ifAdmin : kick
  },
  {
    path: '/admin/localAddSession',
    name: 'Local add Session',
    component: () => import('../views/sessions/AddLocal.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
    //users
  {
    path: '/admin/users',
    name: 'Show Users',
    component: () => import('../views/users/All.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick,
    meta: meta('All Users')
  },
  {
    path: '/admin/users/new',
    name: 'New User',
    component: () => import('../views/users/New.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
  {
    path: '/admin/users/:id',
    name: 'Show User',
    component: () => import('../views/users/One.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
  {
    path: '/admin/users/:id/edit',
    name: 'Edit User',
    component: () => import('../views/users/New.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
    // stats
  {
    path: '/admin/stats/machinajob',
    name: 'View statistics for MachinaJOB',
    component: () => import('../views/Statistics.vue'),
    beforeEnter: (machinaJob && stats) ? ifAdmin : kick
  },
    //coaches
  {
    path: '/admin/coaches',
    name: 'Show Coaches',
    component: () => import('../views/coaches/All.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick,
    meta: meta('All Coaches')
  },
  {
    path: '/admin/coaches/new',
    name: 'New Coach',
    component: () => import('../views/coaches/New.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
  {
    path: '/admin/coaches/:coachId/edit',
    name: 'Edit Coach',
    component: () => import('../views/coaches/New.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
    //collectives
  {
    path: '/admin/collectives',
    name: 'Show Collectives',
    component: () => import('../views/collectives/All.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick,
    meta: meta('All Collectives')
  },
  {
    path: '/admin/collectives/new',
    name: 'New Collective',
    component: () => import('../views/collectives/New.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
  {
    path: '/admin/collectives/:collectiveId/edit',
    name: 'Edit Collective',
    component: () => import('../views/collectives/New.vue'),
    beforeEnter: machinaJob ? ifAdmin : kick
  },
    //qualities
  {
    path: '/admin/qualities/',
    name: 'EditQualities',
    component: () => import('../views/qualities/All.vue'),
    beforeEnter: jobEden ? ifAdmin : kick
  },
    //jobs
  {
    path: '/admin/jobs/',
    name: 'Show Jobs',
    component: () => import('../views/jobs/All.vue'),
    beforeEnter: jobEden ? ifAdmin : kick
  },
  {
    path: '/admin/jobs/new',
    name: 'New Job',
    component: () => import('../views/jobs/New.vue'),
    beforeEnter: jobEden ? ifAdmin : kick
  },
  {
    path: '/admin/jobs/:id/edit',
    name: 'Edit Job',
    component: () => import('../views/jobs/New.vue'),
    beforeEnter: jobEden ? ifAdmin : kick
  },
    //job cat
  {
    path: '/admin/job-categories',
    name: 'Show Job categories',
    component: () => import('../views/categories/All.vue'),
    props: {jobCat: true}, 
    beforeEnter: jobEden ? ifAdmin : kick
  },
  //sectors
  {
    path: '/admin/sectors/',
    name: 'Show sectors',
    component: () => import('../views/jobs/Sectors.vue'),
    beforeEnter: jobEden ? ifAdmin : kick
  },
    //quality cat
  {
    path: '/admin/quality-categories',
    name: 'Show Qualities categories',
    component: () => import('../views/categories/All.vue'),
    props: {qualityCat: true}, 
    beforeEnter: jobEden ? ifAdmin : kick
  },
    //coaching cat
  {
    path: '/admin/coaching-categories/',
    name: 'Coaching Categories',
    component: () => import('../views/categories/All.vue'),
    props: {coachingCat: true}, 
    beforeEnter: ifAdmin
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
