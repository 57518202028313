<template>
  <v-app id="inspire">
    <j-dialog-box v-if="this.$store.state.dialog.display" v-bind="this.$store.state.dialog" ></j-dialog-box>
    <j-loader v-if="this.$store.state.status.loading || this.$store.state.auth.status == 'loading' || this.$store.state.user.status == 'loading'"></j-loader>
    <transition name="popup">
    <j-pop-up v-if="$store.state.popup.display" v-bind="this.$store.state.popup"></j-pop-up>
    </transition>

    <j-drawer :contentNavigation="getContentNavigation" :logout="false"  v-model="drawer" @input="drawer = $event"></j-drawer>

    <j-nav @drawer="drawer = !drawer"></j-nav>

    <v-main>
      <v-container class="fill-height align-start pa-0" fluid >
        <transition name="fade">
          <router-view :key="$route.fullPath" />
        </transition>
      </v-container>
    </v-main>
    <v-footer :fixed="false" class="main-gradient pa-0" >
        <span class="lighter-overlay wi-100 white--text text-center caption">Jobtimise &copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import axios from 'axios'
import { AUTH_LOGOUT } from "@/assets/jobtimise-common/store/actions/auth"
import { jobEden, machinaJob, coach, collective, stats } from '@/../config'

export default {
  name: "app",
  components: {
  },
  data:()=>{
    return {
      drawer: false
    }
  },
  created: function() {
    document.title = this.$route.meta.title || 'Jobtimise Elearning'
    axios.interceptors.response.use(response => {
      return response
    }, async (error) => {
      if (error.response.status === 401) {
        this.$store.dispatch('reset_pending')
        if (this.$store.getters.isProfileLoaded) await this.$store.dispatch(AUTH_LOGOUT)
        if(!['/','/login','/forgot-password'].includes(this.$router.currentRoute.path)) this.$router.push("/")
      }
      return Promise.reject(error)
    })
  },
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'Jobtimise Elearning'
    }
  },
  methods:{
    drawerInput(e){
      this.drawer =e;
    }
  },
  computed: {
    getContentNavigation() {
      return [
        { type: 'separator', text: 'Gestion admin MachinaJob', isActive: machinaJob && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'account-multiple', link: '/admin/users', text: 'Tous les utilisateurs', isActive: machinaJob && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/users/new', text: 'Ajouter un utilisateur', isActive: machinaJob && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'camcorder', link: '/admin/collectives', text: 'Ateliers collectifs', isActive: machinaJob && collective && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/collectives/new', text: 'Ajouter un atelier collectif', isActive: machinaJob && collective && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'folder', link: '/admin/courses', text: 'Toutes les formations', isActive: machinaJob && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/courses/new', text: 'Ajouter une formation', isActive: machinaJob && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/coaching-categories', text: 'Gérer les catégories', isActive: machinaJob && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'podium-gold', link: '/admin/journeys', text: 'Tous les parcours', isActive: machinaJob && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/journeys/new', text: 'Ajouter un parcours', isActive: machinaJob && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'file', link: '/admin/sessions', text: 'Tous les ateliers', isActive: machinaJob && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/sessions/new', text: 'Ajouter un atelier', isActive: machinaJob && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'chart-line', link: '/admin/stats/machinajob', text: 'Statistiques', isActive: machinaJob && stats && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'account-tie', link: '/admin/coaches', text: 'Tous les coachs', isActive: machinaJob && coach && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/coaches/new', text: 'Ajouter un coach', isActive: machinaJob && coach && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { type: 'separator', text: 'Gestion admin JobEden', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'briefcase-outline', link: '/admin/jobs', text: 'Tous les jobs', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/jobs/new', text: 'Ajouter un job', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/sectors', text: 'Gérer les secteurs', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/job-categories', text: 'Gérer les catégories', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'chart-bubble', link: '/admin/qualities', text: 'Toutes les qualités', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/quality-categories', text: 'Gérer les catégories', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { type: 'logout', isActive: this.$store.getters.isAuthenticated },
      ]
    }
  }
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

@import 'assets/jobtimise-common/scss/style.scss';

.v-application--wrap{
  background: $mainGradient;
}
.lighter-overlay {
  background-color: rgba(255, 255, 255, 0.3);
}
</style>
