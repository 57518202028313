<template>
  <div class="screen-min-height wi-100 d-flex align-center justify-center">
    <div class="text-center">
      <img class="ma-auto logo wi-sm-90" alt="Jobtimise" src="/images/logo-white.png">
      <span class="d-block text-center d-flex flex-column" v-if="this.$store.getters.isAuthenticated">
        <v-btn v-if="machinaJob" to="admin/courses" class="primary white--text j-btn mb-2" >Gérer les formations</v-btn>
        <v-btn v-if="jobEden" to="admin/jobs" class="primary white--text j-btn mb-2" >Gérer les jobs</v-btn>
      </span>
    </div>
  </div>
</template>

<script>
import { jobEden, machinaJob } from '@/../config'
export default {
  name: 'Home',
  data() {
    return {
      jobEden: jobEden,
      machinaJob: machinaJob
    }
  }
}
</script>
