import '@fortawesome/fontawesome-free/css/all.min.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { USER_REQUEST } from "@/assets/jobtimise-common/store/actions/user"
import Jcomponents from "@/assets/jobtimise-common"
import Acomponents from "@/components"


if (store.getters.isAuthenticated) {
  axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
  store.dispatch(USER_REQUEST);
}


import vuetify from './plugins/vuetify';

import '@mdi/font/css/materialdesignicons.css'


Vue.use(Jcomponents)
Vue.use(Acomponents)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
