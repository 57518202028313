import Vue from "vue"
import Vuex from "vuex"
import user from "@/assets/jobtimise-common/store/modules/user"
import auth from "@/assets/jobtimise-common/store/modules/auth"
import dialog from "@/assets/jobtimise-common/store/modules/dialog"
import popup from "@/assets/jobtimise-common/store/modules/popup"
import status from "@/assets/jobtimise-common/store/modules/status"
import navigation from '@/assets/jobtimise-common/store/modules/navigation'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    auth,
    dialog,
    popup,
    status,
    navigation
  },
  state:{
  },
  mutations:{
  },
  actions:{
  }
})
